.icon svg {
    width: 30px;
    height: 30px;
    fill: #1559ED;
    margin-right: 10px;
}

.link {
    display: flex;
    align-items: center;
    color: #1559ED;
    font-weight: 600;
}

.contact-links {
    margin-bottom: 10vh;
}


/* form */
.contact-form-container {
    margin-top: 2vh;
    margin-bottom: 5vh;
}

.form-information h1 {
    color: #D5D5EB;
    font-size: 59px;
    margin-bottom: 5vh;
    font-weight: 600;
}

.form-information h3 {
    color: #D5D5EB;
    font-size: 30px;
    line-height: 2vh;
    font-weight: 500;
}

.form-information p {
    color: #D5D5EB;
    font-size: 15px;
    margin-bottom: 3vh;
    font-weight: 350;
}

.contact-form-container .form-inputs form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-form-container .form-inputs form input,
.contact-form-container .form-inputs form textarea {
    padding: 10px;
    background-color: rgba(14, 17, 41, 1);
    border: 1px solid #1559ED;
    color: #D5D5EB;
    border-radius: 15px;
}

.contact-form-container .form-inputs form input:focus,
.contact-form-container .form-inputs form textarea:focus {
    border-color: black !important;
}

.contact-form-container .form-inputs form button {
    padding: 10px;
    border: none;
    background-color: #1559ED;
    cursor: pointer;
    font-weight: 500;
    border-radius: 10px;
}