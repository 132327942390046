#image-track-wrapper {
  position: relative;
  width: 100vw;
  height: 60vh;
  overflow: hidden;
}

#image-track {
  display: flex;
  gap: 4vmin;
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translate(0%, -50%);
  user-select: none;
  /* -- Prevent image highlighting -- */
}

#image-track img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 100% center;
}

.card__ {
  width: 350px;
  place-items: center;
  z-index: 2;
  aspect-ratio: 50/65;
  isolation: isolate;
  position: relative;
  transform-style: preserve-3d;
  perspective: 1000px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: inherit;

  &.clicked {
    &>.card-front__ {
      transform: rotateY(180deg);
    }

    &>.card-back__ {
      transform: rotateY(0deg);
      opacity: 1;
    }
  }
}

.card-front__,
.card-back__ {
  aspect-ratio: inherit;
  transition: inherit;
  width: 100%;
  inset: 0;
  position: absolute;
  overflow: hidden;
  transform-style: preserve-3d;
  transition: transform 0.8s ease;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.card-front__ {
  z-index: 2;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.card-back__ {
  background-color: rgba(14, 17, 41, 0.7);
  backface-visibility: hidden;
  padding: 1.5rem;
  z-index: 2;
  transform: rotateY(-180deg);
  backdrop-filter: blur(4px);
  color: white;
}