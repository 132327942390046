@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

h1 {
  font-size: 49px;
}

h2 {
  font-size: 39px;
}

h3 {
  font-size: 31px;
}

h4 {
  font-size: 25px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}



.portfolio {
  background-color: rgba(14, 17, 41, 1);

}

.sections {
  background-color: rgba(14, 17, 41, 1);
}

html {
  overflow: auto;
  scroll-behavior: smooth;
  background-color: rgba(14, 17, 41, 1);

}

@media (max-width: 768px) {
  html{
    scroll-snap-type: none;
  }
}

body {
  background-color: rgba(14, 17, 41, 1);
  position: relative;
  top: 0;
}

.sections {
  font-family: Roboto Condensed, Lato, Arial, sans-serif !important;
}

.section {
  min-height: 100vh;
  display: flex;
  color: #fff;
  background-size: cover;
  background-position: center;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}




.start {
  background-image: url("../public/assets/img/greeting-background.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  font-family: Roboto Condensed;
}

.about {
  background-color: #02060c;
}

.projects {
  background: linear-gradient(180deg, #0e1129 0%, #02060c 100%);
  overflow-x: hidden;

}

.contact {
  background-color: #02060c;
}

.section-bullet {
  font-size: 25px;
  font-weight: 400;
  color: #D5D5EB;
  font-weight: 400;
}

.section.projects {
  position: relative;
}

.header {
  position: absolute;
  z-index: 100;
}

.section.start {
  max-height: 100vh;
}


@media (max-width: 768px) {
  .section-bullet {
    font-size: 35px;
  }

  .section.start {
    height: 100vh;
  }

  .section.projects {
    display: none;
  }
}