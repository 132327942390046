@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

.project-container {
    min-height: 100vh;
    position: relative;
    scroll-snap-type: y mandatory;
}

.project-container-wrapper {
    left: -3px;
    position: relative;
}

@media (max-width: 768px) {
    .project-container-wrapper {
        left: -0px;
    }
}


.project-bullet-title {
    position: relative;
    ;
}

.project-bullet-title:before {
    background-color: #1b191e;
    border: 2px solid #1559ED;
    border-radius: 50%;
    content: "";
    height: 9px;
    left: -29px;
    position: absolute;
    top: 9px;
    width: 9px;
    z-index: 11;
}


.web-project-container {
    height: 60vh;
    position: relative;
}

.web-project-container:before {
    color: #111344;
    content: "WEB";
    font-family: "Roboto Mono", Courier New, Courier, monospace !important;
    font-size: 270px;
    font-weight: 700;
    position: absolute;
    left: -190px;
    top: -100px;
}

.mobile-project-container {
    height: 60vh;
    position: relative;
}

.mobile-project-container:before {
    color: #111344;
    content: "MOBILE";
    font-family: "Roboto Mono", Courier New, Courier, monospace !important;
    font-size: 270px;
    font-weight: 700;
    position: absolute;
    left: -185px;
    top: -100px;
}

.games-project-container {
    height: 60vh;
    position: relative;
}

.games-project-container:before {
    color: #111344;
    content: "GAMES";
    font-family: "Roboto Mono", Courier New, Courier, monospace !important;
    font-size: 270px;
    font-weight: 700;
    position: absolute;
    left: -185px;
    top: -100px;
}

.drag-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -15vw;
    top: 2vh;
}

.guide {
    display: flex;
    gap: 20px;
}


.guide p {
    width: 100%;
    color: #D5D5EB;
    font-family: sans-serif;
    text-align: center;
    line-height: 1.4;
    margin-top: 15px;
}

.mouse {
    width: 40px;
    height: 40px;
    transform-origin: center bottom;
    animation-name: swinging;
    animation-duration: 1.2s;
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}

.mouse-click,
.mouse-large-click,
.mouse-small-click {
    opacity: 0;
    transform-origin: center center;
    animation-name: click;
    animation-duration: 1.2s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: forward;
    animation-play-state: running;
}


@keyframes swinging {
    0% {
        transform: rotate(-10deg) translate3d(-15px, 0, 0)
    }

    100% {
        transform: rotate(10deg) translate3d(15px, 0, 0)
    }
}

@keyframes click {
    0% {
        opacity: 0
    }

    40% {
        opacity: 1
    }

    100% {
        opacity: 1
    }
}