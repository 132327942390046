.visible {
    opacity: 1;
    transition: opacity 0.3s ease-in, top 0.3s ease-in;
    top: 0;
}

.hidden {
    opacity: 0;
    transition: opacity 0.3s ease-in, top 0.3s ease-in;
    pointer-events: none;
    /* Optional: to prevent interactions with hidden navbar */
    /* top: -50px; */
    /* Adjust top position if needed */
}



.navbar-nav .nav-item.active a {
    color: #fff !important;
    text-decoration: none;
}

@media (max-width: 768px) {
    .navbar-nav {
      flex-direction: column; /* Stack links vertically on small screens */
      align-items: center; /* Center links horizontally */
      z-index: 20;
      height: auto;
      top: 0px;
      padding-bottom: 30px;
    }
   
    .nav-li{
        font-size:25px
    }

    .navbar{
        box-shadow: 0px 10px 10px rgba(2, 6, 12, 0.5);
        background-color: #02060c;
    }
  }