.intro-heading {
    font-size: 3vw;
    font-weight: 700;
    margin-bottom: 1rem;
    margin-top: 30px;
    margin-left: 30px;
}

.intro-paragraph {
    font-size: 2vw;
    font-weight: 300;
    margin-bottom: 1rem;
    text-align: justify;
    margin-left: 30px;
}


.avatar {
    position: absolute;
    margin-top: 50px;
    margin-left: -70px;
    top: 0px;
    animation: animate1 1.5s infinite ease-in-out alternate;
}

.avatar img {
    width: 29vw;
}

.skill-sets {
    text-align: justify;
}

@keyframes animate1 {
    to {
        transform: translateY(10px);
    }
}

@keyframes animate2 {
    to {
        transform: translateY(10px);
    }
}

.avatar-image {
}



.avatar-border {
    position: relative;
    width: 30vw;
    height: 85vh;
    border: 1.5vw solid #1559ED;
    animation: animate2 2s infinite ease-in-out alternate;
}

@media (max-width: 426px) {
    .avatar-border {
        position: relative;
        width: 75vw;
        height: 70vh;
        border: 5vw solid #1559ED;
        animation: animate2 2s infinite ease-in-out alternate;
        left: 5vw
    }

    .avatar img {
        width: 75vw;
    }

    .avatar {
        margin-top: 50px;
        margin-left: -40px;
        top: 0px;
        animation: animate1 1.5s infinite ease-in-out alternate;
    }

    .avatar-container {
        height: 90vh;
    }

    .intro-container {
        scroll-snap-align: start;
        scroll-snap-stop: always;
        min-height: 100vh;
    }

    .intro-heading {
        font-size: 45px;
        margin-left: 0px;

    }

    .intro-paragraph {
        font-size: 21px;
        margin-left: 0px;

    }

    #about-details {
        scroll-snap-align: start;
        scroll-snap-stop: always;
    }
}

/* about-details detail */
#about-details {
    background: linear-gradient(180deg, #02060c 0%, rgba(14, 17, 41, 1) 100%);
    height: auto;
    overflow: hidden;
    position: relative;
}

#about-details:after {
    bottom: -100px;
    /* box-shadow: 0 0 80px 0 black; */
    content: "";
    height: 100px;
    left: 0;
    position: absolute;
    width: 100%
}


#about-details .code-wrp {
    font-family: Roboto Mono, Courier New, Courier, monospace !important;
    font-size: 25px;
    line-height: 1.6
}

#about-details .code-l {
    position: relative;
    z-index: 1
}

#about-details .code-l:after {
    color: #3b3553;
    content: attr(data-line-nr);
    font-family: Roboto Mono, Courier New, Courier, monospace !important;
    font-size: 16px;
    left: -60px;
    line-height: 1.6;
    position: absolute;
    top: 0;
    z-index: 3
}

#about-details .code-l:hover {
    position: relative
}

#about-details .code-l:hover:before {
    background-color: #1D203E;
    content: "";
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    width: 300%;
    z-index: -2
}

#about-details .methods {
    color: #8696ff;
    display: inline
}

#about-details .propname {
    color: #eeff31
}

#about-details .dot {
    color: #3b3553;
    display: inline
}

#about-details .comment {
    color: #78707e;
    display: inline
}

#about-details .scope {
    color: #df96e9;
    display: inline
}

#about-details .prop2 {
    color: #24e394
}

#about-details .string {
    color: #86ffc5
}

#about-details .number {
    color: #e39d24
}

#about-details .cursor:before {
    animation: blink .8s infinite;
    content: "|";
    opacity: 0
}

@keyframes blink {
    0% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@media screen and (max-width:768px) {
    #about-details .dot {
        display: none
    }

    #about-details .code-l:after {
        left: -50px
    }
}