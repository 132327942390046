.start-heading h1 {
  font-size: 49px;
}

.subtitle br{
  display: none;
}

.start-subtitle p {
  font-size: 25px;
  font-weight: 400;
  color: #D5D5EB;
}

.timeline {
  background-color: #1559ED;
  height: 1300%;
  left: -10px;
  position: absolute;
  top: 13px;
  width: 1px;
  z-index: 9
}
 
@media (max-width: 769px) {
  .timeline {
    height: 1300%;
    
  }
}

@media (max-width: 500px) {
  .timeline {
    height: 1700%;
  }
}


@media (max-width: 425px) {
  .timeline {
    height: 1800%;
  }
}

@media (max-width: 376px) {
  .timeline {
    height: 1650%;
  }
}



.timeline.alt {
  height: 100%;
  top: 0
}

.bullet,
.bullet:before {
  position: absolute
}

.bullet:before {
  background-color: #1b191e;
  border: 2px solid #1559ED;
  border-radius: 50%;
  content: "";
  height: 9px;
  left: -4px;
  top: 0;
  width: 9px;
  z-index: 10
}

.bullet-title {
  position: relative
}

.bullet-title:before {
  background-color: #1b191e;
  border: 2px solid #1559ED;
  border-radius: 50%;
  content: "";
  height: 9px;
  left: -29px;
  position: absolute;
  top: 9px;
  width: 9px;
  z-index: 10
}

.bullet-title-project {
  position: relative
}

.bullet-title-project:before {
  background-color: #1b191e;
  border: 2px solid #1559ED;
  border-radius: 50%;
  content: "";
  height: 9px;
  left: -29px;
  position: absolute;
  width: 9px;
  z-index: 10
}

.bullet-title-projects{
  position: relative;
  height: 0;
}

.bullet-title-projects:before {
  background-color: #1b191e;
  border: 2px solid #1559ED;
  border-radius: 50%;
  content: "";
  height: 9px;
  left: -29px;
  top: 10px;
  position: absolute;
  width: 9px;
  z-index: 11
}

.scroll-bottom {
  margin-left: -7px;
  position: relative;
  top: 300px;
  cursor: pointer;
}

.scroll-bottom span {
  bottom: 0;
  color: #1559ED;
  font-size: 14px;
  position: absolute;
  text-shadow: 0 3px 14px #181619, 0 -3px 14px #181619, 3px 0 14px #181619, -3px 0 14px #181619, 0 3px 6px #181619, 0 -3px 6px #181619, 3px 0 6px #181619, -3px 0 6px #181619;
  transform: translate(-15px, 35px) rotate(-90deg);
  z-index: 9
}

.scroll-bottom i {
  text-shadow: 0 3px 14px #181619, 0 -3px 14px #181619, 3px 0 14px #181619, -3px 0 14px #181619, 0 3px 6px #181619, 0 -3px 6px #181619, 3px 0 6px #181619, -3px 0 6px #181619, 0 0 20px #181619
}

.scale-hover {
  position: absolute;
  transform: scaleX(1);
  transition: all .3s cubic-bezier(.165, .84, .44, 1)
}

.scale-hover:hover {
  transform: scale3d(1.1, 1.1, 1)
}

@media (max-width: 768px) {
  .start-heading h1 {
    font-size: 35px;
  }
  .subtitle br{
    display: flex;
  }
}
